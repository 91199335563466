<template>
  <div>
    <van-nav-bar
      v-if="!isTodo"
      title="维修记录"
      :border="false"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="header" :class="{ todo: isTodo }">
      <div class="filter-box-wrapper">
        <van-search
          v-model="query.workNo"
          placeholder="请输入工单编号"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <van-search
          v-model="query.name"
          placeholder="请输入工单名称"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.isActive || item.text }]"
            @click="selectCon(item.popupKey)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="alarm-total">共{{ total }}个结果</div>
        </div>
      </div>
    </div>
    <div class="content">
      <van-pull-refresh
        ref="vanList"
        v-model="refreshing"
        class="alarm-list"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="loadError"
          error-text="加载失败，请稍后再试！"
          @load="onLoad"
        >
          <div
            v-for="item in list"
            :key="item.id"
            class="alarm-item"
            @click="gotoDetail(item)"
          >
            <div class="alarm-header">
              <div class="alarm-title">
                <div class="alarm-name-box">
                  <div class="name-text-box">
                    <span class="name">{{
                      `${item.workNo}(${item.name})`
                    }}</span>
                  </div>
                  <!-- <span v-if="item.shifouyingji === 1" class="make-up-tag"
                    >应急维修</span
                  > -->
                </div>
                <div class="status-box">
                  <van-icon
                    name="riqi"
                    class-prefix="iconfont"
                    class="iconfont-riqi"
                    size="15"
                  />
                  <span class="equipment-name">{{
                    `${item.uploadTime || "--:--:--"}`
                  }}</span>
                </div>
              </div>
              <div class="alarm-status">
                <van-icon
                  :name="statusIconSwitch(item.status)"
                  class-prefix="iconfont"
                  :class="`iconfont-${statusIconSwitch(item.status)}`"
                  size="44"
                />
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <SelectPopupGrade
      v-model="popupVisibleObj[1].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[1].title"
      :list.sync="popupVisibleObj[1].list"
      :visible="popupVisibleObj[1].visiable"
      @change="v => popupGradeChange(v, 0, 1)"
      @close="popupVisibleObj[1].visiable = false"
    />
    <SelectPopupGrade
      v-model="popupVisibleObj[2].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[2].title"
      :list.sync="popupVisibleObj[2].list"
      :visible="popupVisibleObj[2].visiable"
      @change="v => popupGradeChange(v, 1, 2)"
      @close="popupVisibleObj[2].visiable = false"
    />
  </div>
</template>

<script>
import {
  getMaintenanceByPage,
  getMaintenanceTodoByPage,
  getWorkorderTypeSltData,
  getMaintenanceStatusSltData
} from "@/api/psm/workorder";

export default {
  name: "MaintenanceRecords",
  props: {
    isTodo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: {
        orgCode: this.$store.state.login.userInfo.orgCode,
        workNo: "",
        name: "",
        ticketsTypeId: "",
        status: "",
        size: 10,
        page: 1
      },
      filterList: [
        {
          name: "任务类型",
          type: "select",
          text: "",
          popupKey: 1,
          isActive: false
        }
        // {
        //   name: "状态",
        //   type: "select",
        //   popupKey: 2,
        //   text: "",
        //   isActive: false
        // }
      ],
      popupVisibleObj: {
        1: {
          visiable: false,
          title: "任务类型",
          value: "",
          list: []
        },
        2: {
          visiable: false,
          title: "状态",
          value: "",
          list: []
        }
      },
      total: 0,
      list: [],
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false
    };
  },
  computed: {
    statusIconSwitch() {
      return status => {
        const obj = {
          1: "daishangchuanjilu1",
          2: "daishenpi",
          3: "yiguanbi"
        };
        return obj[status];
      };
    }
  },
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    async getList() {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      const params = {
        ...this.query
      };
      try {
        let list = [];
        let total = 0;
        if (this.isTodo) {
          params.currentUser = this.userInfo.id;
          const data = await getMaintenanceTodoByPage(params);
          list = data.list;
          total = data.total;
        } else {
          const data = await getMaintenanceByPage(params);
          list = data.list;
          total = data.total;
        }
        this.total = total;
        this.loadError = false;
        this.loading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.query.page++;
        } else {
          this.finished = true;
        }
      } catch (err) {
        this.loading = false;
        this.finished = true;
        this.loadError = true;
      }
    },
    onSearch(val) {
      this.query.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    onCancel() {
      this.onSearch();
    },
    async selectCon(popupKey) {
      let data = [];
      if (popupKey === 1) {
        data = await getWorkorderTypeSltData();
      } else {
        data = await getMaintenanceStatusSltData();
      }
      this.popupVisibleObj[popupKey].list = data;
      this.popupVisibleObj[popupKey].visiable = true;
    },
    popupGradeChange(v, filterListIdx, popupKey) {
      const vObj = v ? v[0] : {};
      this.popupVisibleObj[popupKey].value = vObj.value || "";
      this.filterList[filterListIdx].text = vObj.label || "";
      if (popupKey === 1) {
        this.query.ticketsTypeId = vObj.value || "";
      } else if (popupKey === 2) {
        this.query.status = vObj.value || "";
      }
      this.onSearch();
    },
    onRefresh() {
      this.query.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.query.page = 1;
        this.total = 0;
        this.refreshing = false;
      }
      this.getList();
    },
    gotoDetail({ ticketId, id }) {
      this.$router.push({
        name: "maintenanceRecordsDetail",
        params: {
          workorderId: ticketId,
          id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header.todo {
  padding: 0;
}
.header {
  padding: 58px 0 0;
  .search-bar {
    padding-bottom: 0;
  }
  .filter-box {
    padding: 10px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid #c7c9d0;
    &::-webkit-scrollbar {
      display: none;
    }
    .filter-item {
      display: inline-block;
      border-radius: 10px;
      background-color: #eaeff5;
      padding: 4px 6px;
      box-sizing: border-box;
      margin: 0 4px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3b4664;
      &.select {
        position: relative;
        padding-right: 18px;
        &::after {
          position: absolute;
          content: "";
          right: 6px;
          top: 8px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }
        &.active {
          &::after {
            border-top-color: $--color-primary;
          }
        }
      }
      &.active {
        background-color: #dcecff;
        color: $--color-primary;
      }
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
    }
  }
  .search-condition {
    display: flex;
    justify-content: center;
    .loading {
      display: inline-block;
    }
    .van-loading__spinner {
      width: 4vw;
      height: 4vw;
    }
    .alarm-total {
      color: #8c8f97;
      text-align: center;
      font-size: 12px;
      padding: 12px 0 0;
    }
  }
}
.content {
  .van-list {
    height: calc(100vh - 229px);
  }
  .alarm-list {
    flex: 1;
    overflow-y: auto;
    z-index: 9;
    .alarm-item {
      padding: 12px 17px 13px 16px;
      border-bottom: 8px solid #eceef2;
      font-size: 4vw;
      .alarm-header {
        display: flex;
        .alarm-title {
          flex: 3;
          max-width: 70vw;
          color: #3b4664;
          .alarm-name-box {
            display: flex;
            align-items: center;
            margin: 0 0 10px;
            .name-text-box {
              // flex: 1;
              max-width: 83%;
              display: inline-block;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .alarm-name {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #3b4664;
                line-height: 20px;
              }
            }
            .make-up-tag {
              display: inline-block;
              height: 16px;
              line-height: 16px;
              font-size: 11px;
              padding: 0 6px;
              color: #d9001b;
              background-color: #f7ccd1;
              text-align: center;
              border-radius: 3px;
              margin: 0 0 0 8px;
              vertical-align: middle;
            }
          }
          .status-box {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .equipment-name {
              font-size: 12px;
              font-weight: 400;
              color: #3b4664;
              line-height: 17px;
              margin: 0 20px 0 4px;
            }
            i {
              color: #aeb3c0;
            }
          }
        }
        .alarm-status {
          flex: 1;
          text-align: right;
          .iconfont-daishangchuanjilu1 {
            color: #b1ecdf;
          }
          .iconfont-yiguanbi {
            color: #aaaaaa;
          }
          .iconfont-daishenpi {
            color: #3a8ee6;
          }
        }
      }
    }
  }
}
</style>
