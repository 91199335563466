<template>
  <div>
    <van-nav-bar
      title="工单详情"
      :border="false"
      fixed
      left-arrow
      @click-left="navBack"
    >
      <template #right>
        <van-icon
          size="24"
          class-prefix="iconfont"
          name="liucheng"
          @click="approveProcess"
        />
      </template>
    </van-nav-bar>
    <div class="form-box">
      <div class="block-space_8_notLast workorder-title">
        {{ `${workorderInfo.workNo}(${workorderInfo.name})` }}
      </div>
      <van-collapse v-model="activeName" @change="collapseChange">
        <van-collapse-item
          class="block-space_8_notLast"
          title="工单信息"
          name="工单信息"
          :border="false"
        >
          <van-form ref="workorderForm" class="van-form__text-color">
            <!-- <template v-if="isShowAuditBtn">
              <van-field
                v-model="form.operationStatus"
                name="operationStatus"
                label="审批"
                placeholder="请选择"
                required
                input-align="right"
                error-message-align="right"
                :disabled="!editable && !approveEditable"
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #input>
                  <van-radio-group
                    v-model="form.operationStatus"
                    direction="horizontal"
                    :disabled="!editable && !approveEditable"
                  >
                    <van-radio name="1">通过</van-radio>
                    <van-radio name="0">退回</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-model="form.approveContent"
                clickable
                name="approveContent"
                label="意见"
                placeholder="请输入内容"
                maxlength="500"
                clearable
                :disabled="!editable && !approveEditable"
                input-align="right"
                error-message-align="right"
                :required="form.operationStatus === '0'"
                :rules="[
                  {
                    required: form.operationStatus === '0',
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
            </template> -->
            <van-field
              v-if="workorderInfo.id"
              v-model="workorderInfo.workNo"
              name="workNo"
              label="工单编码"
              placeholder="请输入内容"
              readonly
              :disabled="true"
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="workorderInfo.name"
              clickable
              name="name"
              label="工单名称"
              placeholder="请输入内容"
              :disabled="true"
              maxlength="500"
              clearable
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
            />
            <van-field
              v-model="workorderInfo.ticketsTypeName"
              clickable
              name="ticketsTypeName"
              label="工单类型"
              placeholder="请选择"
              :disabled="true"
              clearable
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
            />
            <van-field
              v-model="workorderInfo.emergencyType"
              name="emergencyType"
              label="是否应急维修"
              placeholder="请选择"
              :disabled="true"
              clearable
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
            >
              <template #input>
                <span class="disabled-color">{{
                  workorderInfo.emergencyType === "1" ? "是" : "否"
                }}</span>
              </template>
            </van-field>
            <van-field
              v-if="workorderInfo.emergencyType === '1'"
              v-model="workorderInfo.planMaintenanceDate"
              name="planMaintenanceDate"
              label="计划维修时间"
              placeholder="请选择"
              :disabled="true"
              readonly
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
            />
            <van-field
              v-model="workorderInfo.deviceFaultName"
              clickable
              name="deviceFaultName"
              label="关联故障"
              :disabled="true"
              placeholder="请选择"
              clearable
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="workorderInfo.equipmentInfo"
              readonly
              :disabled="true"
              name="equipmentInfo"
              label="关联设备"
              :placeholder="workorderInfo.equipmentInfo || '--'"
              input-align="right"
              error-message-align="right"
            >
            </van-field>
            <van-field
              v-model="workorderInfo.faultReportDepartmentName"
              readonly
              :disabled="true"
              name="departmentName"
              label="申报部门"
              :placeholder="workorderInfo.faultReportDepartmentName || '--'"
              input-align="right"
              error-message-align="right"
            >
            </van-field>
            <van-field
              v-model="workorderInfo.maintenanceDepartmentName"
              clickable
              :disabled="true"
              name="maintenanceDepartmentName"
              label="维修部门"
              placeholder="请选择"
              clearable
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="workorderInfo.costCenterInfoIdName"
              readonly
              :disabled="true"
              name="costCenterInfoIdName"
              label="成本中心"
              :placeholder="workorderInfo.costCenterInfoIdName || '--'"
              input-align="right"
              error-message-align="right"
            >
            </van-field>
            <van-field
              v-model="workorderInfo.maintenancePlan"
              clickable
              name="maintenancePlan"
              label="维修方案"
              placeholder="请输入内容"
              :disabled="true"
              maxlength="500"
              clearable
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
            />
            <van-field
              class="file"
              name="fileList"
              label="安全维修方案"
              readonly
              :disabled="true"
            >
              <template #input>
                <FileUploader
                  v-model="workorderInfo.fileList"
                  :max-count="5"
                  :accept="FILE_ACCEPT_TYPES.IMAGE"
                  :unable-upload="true"
                />
              </template>
            </van-field>
            <van-field
              v-model="workorderInfo.partsNeed"
              clickable
              name="partsNeed"
              label="配件需求"
              :placeholder="workorderInfo.partsNeed || '--'"
              :disabled="true"
              maxlength="500"
              clearable
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="workorderInfo.toolNeed"
              clickable
              name="toolNeed"
              label="工具需求"
              :placeholder="workorderInfo.toolNeed || '--'"
              :disabled="true"
              maxlength="500"
              clearable
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="workorderInfo.cost"
              clickable
              name="cost"
              label="费用预估(元)"
              placeholder="请输入内容"
              :disabled="true"
              clearable
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: 'onChange'
                },
                {
                  pattern: /^\d{0,20}$|^\d{0,18}\.\d{0,2}$/,
                  message: '仅能输入数字，允许输入小数点后两位',
                  trigger: 'onChange'
                }
              ]"
            />
          </van-form>
        </van-collapse-item>
        <van-collapse-item
          v-for="(item, idx) of maintenanceRecords"
          :key="item.id"
          class="block-space_8_notLast"
          :title="`维修记录（${item.departmentName}）`"
          :name="`${idx}-${item.id}`"
          :border="false"
          :value="item.statusName"
        >
          <van-form ref="maintenanceRecordsForm">
            <!-- <template>
              <van-field
                v-model="item.approve"
                name="approve"
                label="审批"
                placeholder="请选择"
                required
                input-align="right"
                error-message-align="right"
                :disabled="true"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #input>
                  <van-radio-group
                    v-model="item.approve"
                    direction="horizontal"
                    :disabled="true"
                  >
                    <van-radio name="1">通过</van-radio>
                    <van-radio name="0">退回</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-model="item.remark"
                clickable
                name="remark"
                label="意见"
                placeholder="请输入内容"
                maxlength="500"
                clearable
                required
                :disabled="true"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: item.approve === '0',
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
            </template> -->
            <van-field
              v-model="item.form.departmentName"
              clickable
              name="departmentName"
              label="维修部门"
              placeholder="请输入内容"
              readonly
              :disabled="true"
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
            />
            <van-field
              :value="(item.form.maintenanceUserName || []).toString()"
              clickable
              name="maintenanceUserName"
              label="维修人"
              placeholder="请选择"
              :disabled="true"
              clearable
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  required: true,
                  message: ' ',
                  trigger: 'onChange'
                }
              ]"
            />
            <van-field
              v-model="item.form.startDate"
              readonly
              clickable
              name="startDate"
              :disabled="true"
              label="开始时间"
              placeholder="请选择"
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="item.form.endDate"
              readonly
              clickable
              name="endDate"
              :disabled="true"
              label="结束时间"
              placeholder="请选择"
              input-align="right"
              error-message-align="right"
            />
            <van-field
              readonly
              clickable
              name="partList"
              :disabled="true"
              label="备件需求"
              placeholder="添加"
              input-align="right"
              error-message-align="right"
            />
            <div class="spare-parts-box">
              <van-field
                v-for="(p, idx) of item.form.partList"
                :key="idx"
                v-model="p.needNum"
                clickable
                :name="p.needNum"
                :disabled="true"
                :label="p.name"
                placeholder="请输入数量"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #label>
                  <span>{{ `${p.code}(${p.name})` }}</span>
                </template>
                <template #right-icon>{{ p.unit }}</template>
              </van-field>
            </div>
            <van-field
              v-model="item.form.workTime"
              clickable
              name="workTime"
              label="工时(人*小时)"
              :placeholder="item.form.workTime || '--'"
              :disabled="true"
              clearable
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  pattern: /^\d{0,50}$|^\d{0,48}\.\d{0,2}$/,
                  message: '仅能输入数字，允许输入小数点后两位',
                  trigger: 'onChange'
                }
              ]"
            />
            <van-field
              v-model="item.form.cost"
              clickable
              name="cost"
              label="维修费用(元)"
              :placeholder="item.form.cost || '--'"
              :disabled="true"
              clearable
              input-align="right"
              error-message-align="right"
              :rules="[
                {
                  pattern: /^\d{0,50}$|^\d{0,48}\.\d{0,2}$/,
                  message: '仅能输入数字，允许输入小数点后两位',
                  trigger: 'onChange'
                }
              ]"
            />
            <van-field
              v-model="item.form.score"
              clickable
              name="score"
              label="评分"
              :placeholder="item.form.score || '--'"
              :disabled="true"
              maxlength="50"
              clearable
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="item.form.processRecord"
              clickable
              name="processRecord"
              label="过程记录"
              placeholder="请输入内容"
              maxlength="500"
              clearable
              :disabled="true"
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="item.form.subsidiaryWork"
              clickable
              name="subsidiaryWork"
              label="辅助工作"
              :placeholder="item.form.subsidiaryWork || '--'"
              maxlength="500"
              clearable
              :disabled="true"
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="item.form.maintenanceExplain"
              clickable
              name="maintenanceExplain"
              label="检修说明"
              :placeholder="item.form.maintenanceExplain || '--'"
              maxlength="500"
              clearable
              :disabled="true"
              input-align="right"
              error-message-align="right"
            />
            <van-field
              v-model="item.form.maintenanceAnalyse"
              clickable
              name="maintenanceAnalyse"
              label="维修分析"
              :placeholder="item.form.maintenanceAnalyse || '--'"
              maxlength="500"
              clearable
              :disabled="true"
              input-align="right"
              error-message-align="right"
            />
            <van-field
              class="file"
              name="fileList"
              label="附件"
              readonly
              :disabled="true"
            >
              <template #input>
                <FileUploader
                  v-model="item.form.fileList"
                  :max-count="5"
                  :unable-upload="true"
                />
              </template>
            </van-field>
          </van-form>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import FileUploader from "@/components/fileuploader/FileUploader";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import {
  getWorkorderInfo,
  getMaintenanceById,
  getMaintenanceInfo
} from "@/api/psm/workorder";

export default {
  components: {
    FileUploader
  },
  data() {
    return {
      appName: window?.globalConfig?.VUE_APP_NAME,
      FILE_ACCEPT_TYPES,
      id: this.$route.params.id,
      activeName: [],
      workorderInfo: {
        // 审批
        operationStatus: "", // 审批
        approveContent: "", // 意见
        currentNodeId: "",
        // 表单
        department: "",
        workNo: "", // 工单编码
        name: "", // 工单名称
        ticketsTypeId: "", // 工单类型
        ticketsTypeName: "",
        emergencyType: "", // 是否应急维修
        planMaintenanceDate: "", // 计划维修时间
        deviceFaultId: "", // 关联故障
        deviceFaultName: "",
        equipmentId: "", // 故障关联设备id,
        equipmentInfo: "", // 设备位号+名称
        maintenanceDepartment: "", // 维修部门
        _maintenanceDepartment: [],
        maintenanceDepartmentName: "", // 维修部门名称
        maintenancePlan: "", // 维修方案
        fileList: [], // 安全维修方案
        partsNeed: "", // 配件需求
        toolNeed: "", // 工具需求
        cost: "", // 费用预估
        status: "",
        faultReportDepartment: "", //申报部门
        faultReportDepartmentName: ""
      },
      maintenanceRecords: [
        {
          id: "",
          department: "",
          departmentName: "",
          name: "",
          status: "",
          statusName: "",
          ticketId: "",
          ticketsTypeId: "",
          ticketsTypeName: "",
          uploadTime: "",
          workNo: "",
          form: {
            // approve: "", // 审批
            // remark: "", // 意见
            status: "",
            department: "", // 维修部门
            departmentName: "",
            maintenanceUser: [], // 维修人
            maintenanceUserName: [],
            startDate: "", // 开始时间
            endDate: "", // 结束时间
            partList: [], // 备件需求
            workTime: "", // 工时
            cost: "", // 维修费用
            score: "", // 评分
            processRecord: "", // 过程记录
            subsidiaryWork: "", // 辅助工作
            maintenanceExplain: "", // 检修说明
            maintenanceAnalyse: "", // 维修分析
            fileList: [] // 附件
          }
        }
      ]
    };
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPageInfo();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    // 获取详情
    getPageInfo() {
      this.getWorkorderInfo();
      this.getMaintenanceById();
    },
    getWorkorderInfo() {
      getWorkorderInfo(this.id).then(res => {
        res._maintenanceDepartment = res.maintenanceDepartment.split(",");
        this.workorderInfo = {
          ...res
        };
      });
    },
    async getMaintenanceById() {
      const params = {
        ticketId: this.id
      };
      const list = await getMaintenanceById(params);
      this.maintenanceRecords = list.map((i, idx) => {
        i.form = {
          // approve: "", // 审批
          // remark: "", // 意见
          status: "",
          department: "", // 维修部门
          departmentName: "",
          maintenanceUser: [], // 维修人
          maintenanceUserName: [],
          startDate: "", // 开始时间
          endDate: "", // 结束时间
          workTime: "", // 工时
          cost: "", // 维修费用
          score: "", // 评分
          processRecord: "", // 过程记录
          subsidiaryWork: "", // 辅助工作
          maintenanceExplain: "", // 检修说明
          maintenanceAnalyse: "", // 维修分析
          fileList: [] // 附件
        };
        return i;
      });
    },
    async collapseChange(names) {
      if (!names.length) return;
      const list = names[names.length - 1].split("-");
      const idx = list[0];
      const id = list[1];
      if (!id) return;
      const data = await getMaintenanceInfo(id);
      data.partList = (data.partList || []).map(i => {
        i.needNum = i.needTotal;
        i.reserveId = i.id;
        return i;
      });
      this.maintenanceRecords[idx].form = data;
    },
    navBack() {
      this.$router.back();
    },
    approveProcess() {
      this.$router.push({
        name: "approveProcess",
        params: {
          id: this.id
        }
      });
    },
    emergencyRepairChange(val) {
      if (val === "1") {
        const curDate = new Date()
          .toLocaleDateString("zh-cn", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          })
          .replace(/\//g, "-");
        this.form.planMaintenanceDate = curDate;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-box {
  padding: 58px 0 0;
  margin: 0 0 16px;
  height: calc(100vh - 58px);
  overflow: auto;
  .file {
    ::v-deep {
      .van-field__control {
        text-align: right;
      }
    }
  }
  .workorder-title {
    color: #aaaaaa;
    text-align: center;
    line-height: 48px;
  }
  .spare-parts-box {
    ::v-deep {
      .van-cell {
        background-color: #f2f5fa;
      }
      .van-field__label {
        width: 130px;
      }
      .van-field__body {
        align-items: flex-start;
      }
      .van-field__right-icon {
        max-width: 60px;
        word-break: break-all;
      }
    }
  }
}
::v-deep .van-collapse-item__content {
  padding: 0;
}
</style>
