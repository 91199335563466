import { render, staticRenderFns } from "./maintenanceRecordsApproveProcessRx.vue?vue&type=template&id=d6915640&scoped=true&"
import script from "./maintenanceRecordsApproveProcessRx.vue?vue&type=script&lang=js&"
export * from "./maintenanceRecordsApproveProcessRx.vue?vue&type=script&lang=js&"
import style0 from "./maintenanceRecordsApproveProcessRx.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./maintenanceRecordsApproveProcessRx.vue?vue&type=style&index=1&id=d6915640&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6915640",
  null
  
)

export default component.exports