<template>
  <div>
    <van-nav-bar :title="title" left-arrow @click-left="onBack">
      <div slot="right" class="right-btn">
        <!-- 隐患流程 -->
        <van-icon
          v-if="disposeStatus === 9"
          class-prefix="iconfont"
          class="right-btn-icon"
          name="gzl3"
          @click="goFlowPage"
        />
      </div>
    </van-nav-bar>
    <div class="block-space-left-right">
      <step-progress
        :active-thickness="5"
        :current-step="currentStep"
        :line-thickness="4"
        :passive-thickness="3"
        :steps="mySteps"
        active-color="#1890ff"
        icon-class="van-icon van-icon-success"
        other-icon="iconfont iconfont-ico-ProgressDot"
        passive-color="#EBEBEB"
      />
    </div>
    <van-form :show-error-message="false" label-width="23vw" @submit="onSubmit">
      <!--      <van-field-->
      <!--        v-model="info.name"-->
      <!--        :rules="[{ required: true, message: '请填写隐患名称' }]"-->
      <!--        label="隐患名称"-->
      <!--        name="name"-->
      <!--        placeholder="请输入内容"-->
      <!--        required-->
      <!--        maxlength="100"-->
      <!--        @input="handleInput('name')"-->
      <!--      />-->
      <van-field
        :rules="[{ required: true, message: '请填写隐患名称' }]"
        :value="info.name"
        clickable
        label="隐患名称"
        name="name"
        placeholder="请选择"
        readonly
        required
        @click="nameVisible = true"
      />
      <van-field
        :rules="[{ required: true, message: '请选择所属部门' }]"
        :value="info.departmentName"
        clickable
        label="所属部门"
        name="department"
        placeholder="请选择"
        readonly
        required
        @click="departmentVisible = true"
      />
      <van-field
        v-model="info.poiName"
        :rules="[{ message: '请填写部位名称', required: isRh }]"
        label="隐患部位"
        :required="isRh"
        name="poiName"
        placeholder="请输入内容"
        readonly
        @click="facilityVisible = true"
      />
      <van-field
        :rules="[{ required: true, message: '请填写隐患级别' }]"
        :value="info.troubleLevelName"
        clickable
        label="隐患级别"
        name="troubleLevel"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'troubleLevel')"
      />
      <van-field
        :rules="[{ required: true, message: '请填写隐患类别' }]"
        :value="info.categoryName"
        clickable
        label="隐患类别"
        name="categoryId"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'categoryId')"
      />
      <van-field
        :value="info.dangerTypeName"
        label="危害因素类型"
        name="dangerType"
        placeholder="请选择"
        clickable
        readonly
        @click="handlerTypesClick($event, 'dangerType')"
      />
      <van-field
        v-if="showContractor"
        :rules="[{ required: true, message: '请填写隐患名称' }]"
        :value="info.contractorName"
        clickable
        label="承包商"
        name="contractorId"
        placeholder="请选择"
        readonly
        required
        @click="handlerTypesClick($event, 'contractorId')"
      />
      <van-field
        :value="info.planName"
        clickable
        label="所属计划"
        name="planId"
        placeholder="请选择"
        readonly
        @click="handlerTypesClick($event, 'planId')"
      />
      <van-field
        v-if="hasTroubleType"
        :rules="[{ required: true }]"
        label="隐患分类"
        name="radio"
        required
      >
        <template #input>
          <van-radio-group
            v-model="info.typeId"
            direction="horizontal"
            @change="onChangeTypeId"
          >
            <van-radio
              v-for="item in troubleDics"
              :key="item.id"
              :name="item.id"
              >{{ item.name }}</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        :rules="[{ required: true }]"
        label="发现方式"
        name="radio"
        required
      >
        <template #input>
          <van-radio-group v-model="info.discoveryId" direction="horizontal">
            <van-radio
              v-for="item in discoveryData"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
            <!-- :disabled="+item.value === 1" -->
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="info.description"
        autosize
        label="备注"
        maxlength="500"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('description')"
      />
      <van-field
        v-model="info.gist"
        autosize
        label="检查依据"
        maxlength="500"
        name="gist"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('gist')"
      />
      <van-field
        v-model="info.suggest"
        autosize
        label="整改措施"
        maxlength="500"
        name="suggest"
        placeholder="请输入内容"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('suggest')"
      />
      <van-field
        class="details-page__last-from-item"
        label="现场照片"
        name="uploader"
      >
        <template #input>
          <image-uploader
            ref="image-uploader"
            v-model="info.fileList"
            :max-count="5"
            @change="onImageChange"
          />
        </template>
      </van-field>
      <div class="details-page__footer-button-wrapper--fixed">
        <van-button
          :disabled="loading2.status"
          :loading="loading2.status"
          :loading-text="loading2.text"
          class="van-button van-button--save-btn-bgc"
          @click="onSave"
          >存草稿</van-button
        >
        <van-button
          v-if="isAuthAllow"
          :disabled="loading.status"
          :loading="loading.status"
          :loading-text="loading.text"
          class="van-button van-button--commit-btn-bgc"
          native-type="submit"
          style="color: #fff"
          type="info"
          >提交</van-button
        >
      </div>
      <select-popup
        :choose-value="popupValue"
        :data="popupData"
        :title="popupTitle"
        :visible="popupVisible"
        :popup-style="{
          height: '70vh'
        }"
        @close="onPopupClose"
        @confirm="onPopupConfirm"
      />
      <cascade-department
        v-model="info.department"
        :visible="departmentVisible"
        disabled-key="--"
        @close="departmentVisible = false"
        @confirm="onTreeConfirm"
      />
      <add-facility
        v-model="facilityMap"
        :visible="facilityVisible"
        :department="info.department"
        activated="14"
        auth="org"
        title="隐患部位"
        @close="facilityVisible = false"
        @confirm="onFacilityConfirm"
      />
      <!--      <name-autocomplete-->
      <!--        title="隐患名称"-->
      <!--        :visible="nameVisible"-->
      <!--        :data="troubleList"-->
      <!--        value-key="id"-->
      <!--        label-key="name"-->
      <!--        :max="100"-->
      <!--        @confirm="onNameConfirm"-->
      <!--        @close="nameVisible = false"-->
      <!--      />-->
      <entry-trouble-name
        title="隐患名称"
        :visible="nameVisible"
        @confirm="onNameConfirm"
        @close="nameVisible = false"
      />
    </van-form>
  </div>
</template>

<script>
import StepProgress from "@/views/trouble/components/vue-step-progress/vue-step-progress.min";
import "@/views/trouble/components/vue-step-progress/main.css";
import { filterEmoji, operateMessage, parseTime } from "@/utils";
import { discoveryData } from "../utils/constant";
import { loading } from "@/utils/constant";
import {
  commitTrouble,
  commitTroubleById,
  getSelectPlans,
  getTroubleInfoById,
  saveTrouble,
  saveTroubleById,
  queryDangerTypes,
  getTroubleDicItems,
  getModuleOptionItems
} from "@/api/psm/trouble";
import {
  getDepartmentByFacilityId,
  getSelectContractorsNames
} from "@/api/base";
import { mapMutations, mapState } from "vuex";
import getSelectMixin from "@/views/trouble/mixin/getSelectMixin";
import CascadeDepartment from "@/components/CascadeDepartment";
import SelectPopup from "@/components/SelectPopup";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import AddFacility from "@/components/AddFacility";
import EntryTroubleName from "@/views/trouble/trouble-fix/components/EntryTroubleName";

// 隐患分类字典表id
const TROUBLE_DIC_ID = "10400101001";
// 隐患分类字典表详情id，目前只能写死，是1
const TROUBLE_DIC_ITEM_ID = "1";
export default {
  components: {
    EntryTroubleName,
    AddFacility,
    ImageUploader,
    SelectPopup,
    CascadeDepartment,
    StepProgress
  },
  mixins: [getSelectMixin],
  data() {
    return {
      isAuthAllow: false,
      showContractor: false,
      currentStep: 0,
      mySteps: ["登记", "确认", "接收", "整改", "验收"],
      title: "隐患登记",
      popupVisible: false,
      facilityVisible: false,
      nameVisible: false,
      popupTitle: "",
      popupData: [],
      loading: { ...loading },
      loading2: { ...loading },
      troubleList: [],
      info: {
        // 任务关联ID
        taskRecordId: "",
        file: [],
        fileList: [],
        name: "",
        orgCode: "",
        orgName: "",
        troubleLevel: "",
        // 隐患类别
        categoryId: "",
        categoryName: "",

        contractorId: "",
        contractorName: "",
        description: "",
        // 所属排查计划
        planId: "",
        planName: "",
        // 危害因素类型
        dangerType: "",
        dangerTypeName: "",
        // 所属部门
        department: "",
        departmentName: "",
        // 发现人部门，为登录用户
        discoverDeptId: "",
        // 发现人
        discoverer: "",
        // 是否已整改
        dispose: "0",
        // 隐患发现方式
        discoveryId: "1",
        discoverDate: "",
        // 检查依据
        gist: "",
        // 整改建议
        suggest: "",
        // 隐患分类
        typeId: ""
      },
      discoveryData,
      planTypes: [],
      contractorTypes: [],
      dangerTypes: [],
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      departmentVisible: false,
      selectValue: "",
      popupTypesConf: {
        troubleLevel: {
          title: "隐患级别",
          keyName: "troubleLevelName",
          typesKey: "levelTypes"
        },
        categoryId: {
          title: "隐患类别",
          keyName: "categoryName",
          typesKey: "classesTypes"
        },
        contractorId: {
          title: "承包商",
          keyName: "contractorName",
          typesKey: "contractorTypes"
        },
        planId: {
          title: "所属计划",
          keyName: "planName",
          typesKey: "planTypes"
        },
        dangerType: {
          title: "危害因素类型",
          keyName: "dangerTypeName",
          typesKey: "dangerTypes"
        }
      },
      facilityMap: {
        label: "",
        value: "",
        type: ""
      },
      disposeStatus: "",
      troubleDics: [],
      optionItems: []
    };
  },
  async created() {
    this.optionItems = await getModuleOptionItems();
    this.getTroubleDicItems();
    this.initTypes();
    const { orgCode } = this.userInfo;
    this.info.orgCode = orgCode;
    this.getSelectContractorsNames();
    this.id = this.$route.query.id || "";
    this.disposeStatus = this.$route.query.disposeStatus;
    this.isAuthAllow = this.$route.query.add || this.isAuthAllow;
    this.getDangerType();
    if (this.id) {
      this.getInfo();
    } else {
      // 考虑新增的时候可以存草稿
      this.isAuthAllow = true;
      this.info.department = this.userInfo.department;
      this.info.departmentName = this.userInfo.departmentName;
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    hasTroubleType() {
      const optionItem =
        this.optionItems.find(i => i.id === TROUBLE_DIC_ID) || {};
      return optionItem.itemValue === "1";
    }
  },
  methods: {
    ...mapMutations(["setRefreshList"]),
    onChangeTypeId(val) {
      const troubleDics = this.troubleDics || [];
      const selectItem = troubleDics.find(i => i.id === val) || {};
      const typeName = selectItem.name || "";
      this.info.typeName = typeName;
      console.log(this.info);
    },
    async getTroubleDicItems() {
      if (!this.hasTroubleType) return;
      this.troubleDics = await getTroubleDicItems(TROUBLE_DIC_ITEM_ID);
    },
    async getDangerType() {
      try {
        const res = await queryDangerTypes();
        if (res) {
          this.dangerTypes = res;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goFlowPage() {
      this.$router.push({
        name: "trouble-flow",
        query: { id: this.id, status: this.disposeStatus }
      });
    },
    onBack() {
      const isHome = this.$route.query?.home;
      if (isHome === "true") {
        this.$router.push("/");
      } else {
        this.$router.back();
      }
    },
    onNameConfirm(name) {
      this.info.name = name;
    },
    handleInput(key) {
      this.info[key] = filterEmoji(this.info[key]);
    },
    async onFacilityConfirm(facility) {
      this.info.poiName = facility.label;
      this.info.poiType = facility.type;
      this.info.poiId = facility.value;
      if (!facility || !Object.keys(facility).length || !facility.value) {
        // this.info.department = "";
        // this.info.departmentName = "";
        return false;
      }
      try {
        const ret = await getDepartmentByFacilityId(facility.value);
        this.info.department = ret.department;
        this.info.departmentName = ret.departmentName;
      } catch (e) {
        console.log("onFacilityChange -> e", e);
      }
    },
    formatterGetInfo(result) {
      this.info.fileList =
        result.files &&
        result.files.map(file => {
          return {
            url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${file.id}`,
            name: file.name,
            fileInfo: file,
            isImage: true
          };
        });
      this.$refs["image-uploader"].setInnerValue(this.info.fileList);
      this.facilityMap.label = this.info.poiName;
      this.facilityMap.value = this.info.poiId;
      this.facilityMap.type = this.info.poiType;
    },
    async getInfo() {
      try {
        const res = await getTroubleInfoById(this.id);
        this.info = { ...res };
        this.formatterGetInfo(res);
        if (this.info.contractorId && this.info.contractorId !== "-1") {
          this.showContractor = true;
        }
        // this.selectValue = this.info.department;
      } catch (e) {
        console.log("getInfo -> e", e);
      }
    },

    async getSelectContractorsNames() {
      try {
        const params = {
          orgCodeInList: this.info.orgCode
        };
        this.contractorTypes = await getSelectContractorsNames(params);
      } catch (error) {
        console.log(error);
      }
    },
    async initTypes() {
      try {
        const { orgCode } = this.userInfo;
        let params = { orgCode };
        params.status = 0;
        const rets = await Promise.all([getSelectPlans(params)]);
        this.planTypes = rets[0];
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onImageChange(val) {
      this.info.fileList = val;
    },
    onTreeConfirm({ label }) {
      this.info.departmentName = label;
      this.facilityMap.label = this.info.poiName = "";
      this.facilityMap.type = this.info.poiType = "";
      this.facilityMap.value = this.info.poiId = "";
    },
    handlerTypesClick(ev, key) {
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.info[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;

      this.popupData = [...this[popupTypesConf[key].typesKey]];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
      console.log(popupTypesConf, this.popupData, this.info);
    },
    onPopupConfirm(row) {
      this.info[this.popupType] = row.value;
      this.info[this.popupTypeName] = row.label;
      if (this.popupType === "categoryId") {
        if (row.contractorStatus === 1) {
          this.showContractor = true;
        } else {
          this.showContractor = false;
          // 清空关联的下级
          this.info.contractorId = "";
          this.info.contractorName = "";
        }
      }
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    processDefaultSaveInfo() {
      const { orgCode, id, department, mobile } = this.userInfo;
      this.info.file =
        this.info.fileList &&
        this.info.fileList.map(item => item && item.fileInfo);
      delete this.info.fileList;
      this.info.taskRecordId = -1;
      this.info.itemRecordsId = -1;
      this.info.checkItemId = -1;
      this.info.orgCode = orgCode;
      // 发现相关
      this.info.discoverer = id;
      this.info.discoverDeptId = department;
      this.info.discovererMobile = mobile;
      this.info.discoverDate = parseTime(Date.now(), "{y}-{m}-{d} {h}:{i}:{s}");
    },
    async onSave() {
      this.loading2.status = true;
      this.processDefaultSaveInfo();
      try {
        if (this.id) {
          // 更新草稿
          const r = await saveTroubleById(this.id, this.info);
          operateMessage(r, "更新草稿");
          if (r) {
            this.setRefreshList(true);
            this.$router.push({ name: "trouble-list" });
          }
        } else {
          // 保存草稿
          const r = await saveTrouble(this.info);
          operateMessage(r, "保存草稿");
          if (r) {
            this.setRefreshList(true);
            this.$router.push({ name: "trouble-list" });
          }
        }
      } catch (e) {
        console.log("onSave -> e", e);
        this.loading2.status = false;
      }
    },
    async onSubmit(values) {
      this.loading.status = true;
      this.processDefaultSaveInfo();
      try {
        if (this.id) {
          const r = await commitTroubleById(this.id, this.info);
          operateMessage(r, "提交隐患");
          if (r) {
            this.setRefreshList(true);
            this.$router.push({ name: "trouble-list" });
          }
        } else {
          const r = await commitTrouble(this.info);
          operateMessage(r, "提交隐患");
          if (r) {
            // 同步全局的消息
            this.getMessageCountNoticesAction();
            this.setRefreshList(true);
            this.$router.push({ name: "trouble-list" });
          }
        }
      } catch (e) {
        console.log("onSubmit -> e", e);
        this.loading.status = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$--btn-bgc: #fd687d;
.van-form {
  height: 70vh;
  overflow: auto;
  .van-radio-group {
    display: flex;
  }
}
.right-btn-icon {
  font-size: 24px;
}
// .support-mobile {
//   margin: 3vh;
// }
.van-button {
  color: #fff;
  font-size: 16px;
}
.van-button--save-btn-bgc {
  background-color: $--button-color-feature;
  border-color: $--button-color-feature;
  box-shadow: $--button-color-feature-shadow;
  margin-right: 3vw;
}
.van-button--commit-btn-bgc {
  background-color: $--button-color-save;
  border-color: $--button-color-save;
  box-shadow: $--button-color-save-shadow;
}
</style>
