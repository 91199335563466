<template>
  <div>
    <div class="risk-wrapper">
      <div class="risk-index-top">
        <van-nav-bar left-arrow :border="false" @click-left="$router.back()">
          <template #title>
            <div class="risk-title-box">
              <span
                v-for="item in tabs"
                :key="item.value"
                :class="{ active: curTab === item.value }"
                class="risk-title-item"
                @click.prevent="listSwitch(item.value)"
                >{{ item.text }}</span
              >
            </div>
          </template>
          <!-- <template #right>
            <i
              v-if="btnAuth"
              class="addiconfont iconfont iconfont-xinzengfengxian"
              @click="$emit('add')"
            ></i>
          </template> -->
        </van-nav-bar>
      </div>
    </div>
    <div>
      <components :is="curTab" is-todo></components>
    </div>
  </div>
</template>

<script>
import workorderListRx from "./workOrderRxList.vue";
import maintenanceRecordsRx from "./maintenanceRecordsRx.vue";

export default {
  components: {
    workorderListRx,
    maintenanceRecordsRx
  },
  name: "WorkOderTodoList",
  data() {
    return {
      curTab: "workorderListRx",
      tabs: [
        {
          text: "工单",
          value: "workorderListRx"
        },
        {
          text: "维修记录",
          value: "maintenanceRecordsRx"
        }
      ]
    };
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    listSwitch(val) {
      this.curTab = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-wrapper {
  /*height: 100%;*/
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .van-nav-bar {
    ::v-deep.van-nav-bar__title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .addiconfont {
      font-size: 24px;
    }
  }
  .risk-title-box {
    position: relative;
    display: inline-block;
    width: 160px;
    font-size: 0;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    overflow: hidden;
    .risk-title-item {
      display: inline-block;
      width: 50%;
      height: 100%;
      font-size: 14px;
      color: #fff;
      background-color: #1676ff;
      border: 1px solid #fff;
      box-sizing: border-box;
      &.active {
        color: #1676ff;
        background-color: #fff;
        box-sizing: border-box;
      }
      &:nth-child(1) {
        border-radius: 4px 0 0 4px;
        border-right: none;
      }
      &:nth-child(2) {
        border-radius: 0 4px 4px 0;
        border-left: none;
      }
    }
  }
  .risk-content {
    flex: 1;
    overflow-y: auto;
    .tab-panl {
      position: relative;
      height: 100%;
    }
  }
}
</style>
