var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":_vm.type === 'add' ? '新增工单' : '工单详情',"border":false,"fixed":"","left-arrow":""},on:{"click-left":_vm.navBack},scopedSlots:_vm._u([(_vm.type !== 'add')?{key:"right",fn:function(){return [_c('van-icon',{attrs:{"size":"24","class-prefix":"iconfont","name":"liucheng"},on:{"click":_vm.approveProcess}})]},proxy:true}:null],null,true)}),(_vm.loaded)?_c('div',{staticClass:"form-box",attrs:{"loading":_vm.loaded}},[_c('van-form',{ref:"form",staticClass:"form van-form__text-color"},[(_vm.isShowAuditBtn)?[_c('van-field',{attrs:{"name":"operationStatus","label":"审批","placeholder":"请选择","required":_vm.editable || _vm.approveEditable,"input-align":"right","error-message-align":"right","disabled":!_vm.editable && !_vm.approveEditable,"rules":[
            {
              required: true,
              message: '请选择',
              trigger: 'onChange'
            }
          ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal","disabled":!_vm.editable && !_vm.approveEditable},model:{value:(_vm.form.operationStatus),callback:function ($$v) {_vm.$set(_vm.form, "operationStatus", $$v)},expression:"form.operationStatus"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("通过")]),_c('van-radio',{attrs:{"name":"0"}},[_vm._v("退回")])],1)]},proxy:true}],null,false,1823885615),model:{value:(_vm.form.operationStatus),callback:function ($$v) {_vm.$set(_vm.form, "operationStatus", $$v)},expression:"form.operationStatus"}}),_c('van-field',{attrs:{"clickable":"","name":"approveContent","label":"意见","placeholder":_vm.editable || _vm.approveEditable ? '请输入内容' : '',"maxlength":"500","clearable":"","disabled":!_vm.editable && !_vm.approveEditable,"input-align":"right","error-message-align":"right","required":_vm.form.operationStatus === '0' && (_vm.editable || _vm.approveEditable),"rules":[
            {
              required: _vm.form.operationStatus === '0',
              message: ' ',
              trigger: 'onChange'
            }
          ]},model:{value:(_vm.form.approveContent),callback:function ($$v) {_vm.$set(_vm.form, "approveContent", $$v)},expression:"form.approveContent"}})]:_vm._e(),(_vm.form.id)?_c('van-field',{attrs:{"name":"workNo","label":"工单编码","placeholder":"请输入内容","readonly":"","disabled":!_vm.editable,"input-align":"right","error-message-align":"right"},model:{value:(_vm.form.workNo),callback:function ($$v) {_vm.$set(_vm.form, "workNo", $$v)},expression:"form.workNo"}}):_vm._e(),_c('van-field',{attrs:{"clickable":"","name":"name","label":"工单名称","placeholder":"请输入内容","disabled":!_vm.editable,"maxlength":"500","clearable":"","required":_vm.editable,"input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('van-field',{attrs:{"clickable":"","is-link":_vm.editable,"name":"ticketsTypeName","label":"工单类型","placeholder":"请选择","disabled":!_vm.editable,"required":_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.workorderTypeSelect()}},model:{value:(_vm.form.ticketsTypeName),callback:function ($$v) {_vm.$set(_vm.form, "ticketsTypeName", $$v)},expression:"form.ticketsTypeName"}}),_c('van-field',{attrs:{"name":"emergencyType","label":"是否应急维修","placeholder":"请选择","disabled":!((_vm.isEditField('emergencyType') && _vm.isApproveRequired) || _vm.editable),"required":(_vm.isEditField('emergencyType') && _vm.isApproveRequired) || _vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [(
              !(
                (_vm.isEditField('emergencyType') && _vm.isApproveRequired) ||
                _vm.editable
              )
            )?_c('span',{staticClass:"disabled-color"},[_vm._v(_vm._s(("" + (!!Number(_vm.form.emergencyType) ? "是" : "否"))))]):_c('van-radio-group',{attrs:{"direction":"horizontal","disabled":!(
                (_vm.isEditField('emergencyType') && _vm.isApproveRequired) ||
                _vm.editable
              )},on:{"change":_vm.emergencyRepairChange},model:{value:(_vm.form.emergencyType),callback:function ($$v) {_vm.$set(_vm.form, "emergencyType", $$v)},expression:"form.emergencyType"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("是")]),_c('van-radio',{attrs:{"name":"0"}},[_vm._v("否")])],1)]},proxy:true}],null,false,1388671388),model:{value:(_vm.form.emergencyType),callback:function ($$v) {_vm.$set(_vm.form, "emergencyType", $$v)},expression:"form.emergencyType"}}),_c('van-field',{attrs:{"name":"planMaintenanceDate","label":"计划维修时间","placeholder":"请选择","disabled":!_vm.editable || _vm.form.emergencyType == '1',"required":_vm.editable,"readonly":"","input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":_vm.planMaintenanceDateClick},model:{value:(_vm.form.planMaintenanceDate),callback:function ($$v) {_vm.$set(_vm.form, "planMaintenanceDate", $$v)},expression:"form.planMaintenanceDate"}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c('van-datetime-picker',{attrs:{"type":"date"},on:{"confirm":_vm.dateConfirm,"cancel":function($event){_vm.showDatePicker = false}},model:{value:(_vm.datePickerVal),callback:function ($$v) {_vm.datePickerVal=$$v},expression:"datePickerVal"}})],1),_c('van-field',{attrs:{"clickable":"","is-link":_vm.editable,"name":"deviceFaultName","label":"关联故障","disabled":!_vm.editable,"placeholder":_vm.editable ? '请选择' : '--',"clearable":"","required":_vm.editable,"rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ],"input-align":"right","error-message-align":"right"},on:{"click":function($event){return _vm.linkFaultSelect()}},model:{value:(_vm.form.deviceFaultName),callback:function ($$v) {_vm.$set(_vm.form, "deviceFaultName", $$v)},expression:"form.deviceFaultName"}}),_c('van-field',{attrs:{"readonly":"","disabled":!_vm.editable,"name":"equipmentInfo","label":"关联设备","placeholder":_vm.editable ? '请输入内容' : '--',"input-align":"right","error-message-align":"right"},model:{value:(_vm.form.equipmentInfo),callback:function ($$v) {_vm.$set(_vm.form, "equipmentInfo", $$v)},expression:"form.equipmentInfo"}}),_c('van-field',{attrs:{"readonly":"","disabled":!_vm.editable,"name":"equipmentInfo","label":"申报部门","placeholder":_vm.editable ? '请输入内容' : '--',"input-align":"right","error-message-align":"right"},model:{value:(_vm.form.faultReportDepartmentName),callback:function ($$v) {_vm.$set(_vm.form, "faultReportDepartmentName", $$v)},expression:"form.faultReportDepartmentName"}}),_c('van-field',{attrs:{"clickable":"","is-link":(_vm.isEditField('maintenanceDepartment') && _vm.isApproveRequired) ||
            _vm.editable,"disabled":!(
            (_vm.isEditField('maintenanceDepartment') && _vm.isApproveRequired) ||
            _vm.editable
          ),"name":"maintenanceDepartmentName","label":"维修部门","placeholder":"请选择","clearable":"","required":(_vm.isEditField('maintenanceDepartment') && _vm.isApproveRequired) ||
            _vm.editable,"rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ],"input-align":"right","error-message-align":"right"},on:{"click":function($event){((_vm.isEditField('maintenanceDepartment') && _vm.isApproveRequired) ||
            _vm.editable) &&
            (_vm.MDVisiable = true)}},model:{value:(_vm.form.maintenanceDepartmentName),callback:function ($$v) {_vm.$set(_vm.form, "maintenanceDepartmentName", $$v)},expression:"form.maintenanceDepartmentName"}}),_c('van-field',{attrs:{"name":"contractorMaintain","label":"承包商维修","placeholder":"请选择","disabled":!_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            message: ' ',
            trigger: 'onChange'
          }
        ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [(!_vm.editable)?_c('span',{staticClass:"disabled-color"},[_vm._v(" "+_vm._s(("" + (!!Number(_vm.form.contractorMaintain) ? "是" : "否")))+" ")]):_c('van-radio-group',{attrs:{"direction":"horizontal","disabled":!_vm.editable},model:{value:(_vm.form.contractorMaintain),callback:function ($$v) {_vm.$set(_vm.form, "contractorMaintain", $$v)},expression:"form.contractorMaintain"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("是")]),_c('van-radio',{attrs:{"name":"0"}},[_vm._v("否")])],1)]},proxy:true}],null,false,3959514627),model:{value:(_vm.form.contractorMaintain),callback:function ($$v) {_vm.$set(_vm.form, "contractorMaintain", $$v)},expression:"form.contractorMaintain"}}),_c('van-field',{attrs:{"clickable":"","is-link":_vm.editable,"name":"costCenterInfoIdName","label":"成本中心","placeholder":"请选择","disabled":!_vm.editable,"required":_vm.appName === 'RT',"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            required: _vm.appName === 'RT',
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.costCenterSelect()}},model:{value:(_vm.form.costCenterInfoIdName),callback:function ($$v) {_vm.$set(_vm.form, "costCenterInfoIdName", $$v)},expression:"form.costCenterInfoIdName"}}),(_vm.form.contractorMaintain === '1')?_c('van-field',{attrs:{"clickable":"","is-link":_vm.editable,"name":"contractors","label":"承包商单位","placeholder":"请选择","disabled":!_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){_vm.contractorsVisiable = true}},model:{value:(_vm.form.contractorsName),callback:function ($$v) {_vm.$set(_vm.form, "contractorsName", $$v)},expression:"form.contractorsName"}}):_vm._e(),_c('van-field',{attrs:{"clickable":"","name":"maintenancePlan","label":"维修方案","placeholder":"请输入内容","disabled":!((_vm.isEditField('maintenancePlan') && _vm.isApproveRequired) || _vm.editable),"maxlength":"500","clearable":"","required":(_vm.isEditField('maintenancePlan') && _vm.isApproveRequired) || _vm.editable,"input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ]},model:{value:(_vm.form.maintenancePlan),callback:function ($$v) {_vm.$set(_vm.form, "maintenancePlan", $$v)},expression:"form.maintenancePlan"}}),_c('van-field',{staticClass:"file",attrs:{"name":"fileList","label":"安全维修方案","readonly":"","disabled":!_vm.editable},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('FileUploader',{attrs:{"max-count":5,"accept":_vm.FILE_ACCEPT_TYPES.IMAGE,"unable-upload":!_vm.editable},model:{value:(_vm.form.fileList),callback:function ($$v) {_vm.$set(_vm.form, "fileList", $$v)},expression:"form.fileList"}})]},proxy:true}],null,false,156754759)}),_c('van-field',{attrs:{"clickable":"","name":"partsNeed","label":"配件需求","placeholder":_vm.editable ? '请输入内容' : '--',"disabled":!((_vm.isEditField('partsNeed') && _vm.isApproveRequired) || _vm.editable),"required":_vm.isEditField('partsNeed') && _vm.isApproveRequired,"maxlength":"500","clearable":"","input-align":"right","error-message-align":"right"},model:{value:(_vm.form.partsNeed),callback:function ($$v) {_vm.$set(_vm.form, "partsNeed", $$v)},expression:"form.partsNeed"}}),_c('van-field',{attrs:{"clickable":"","name":"toolNeed","label":"工具需求","placeholder":_vm.editable ? '请输入内容' : '--',"disabled":!((_vm.isEditField('toolNeed') && _vm.isApproveRequired) || _vm.editable),"required":_vm.isEditField('toolNeed') && _vm.isApproveRequired,"maxlength":"500","clearable":"","input-align":"right","error-message-align":"right"},model:{value:(_vm.form.toolNeed),callback:function ($$v) {_vm.$set(_vm.form, "toolNeed", $$v)},expression:"form.toolNeed"}}),_c('van-field',{attrs:{"clickable":"","name":"cost","label":"费用预估(元)","placeholder":"请输入内容","disabled":!((_vm.isEditField('cost') && _vm.isApproveRequired) || _vm.editable),"clearable":"","required":(_vm.isEditField('cost') && _vm.isApproveRequired) || _vm.editable,"input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          },
          {
            pattern: /^\d{0,20}$|^\d{0,18}\.\d{0,2}$/,
            message: '仅能输入数字，允许输入小数点后两位',
            trigger: 'onChange'
          }
        ]},model:{value:(_vm.form.cost),callback:function ($$v) {_vm.$set(_vm.form, "cost", $$v)},expression:"form.cost"}}),_c('van-field',{attrs:{"clickable":"","is-link":(_vm.isEditField('costTypeCode') && _vm.isApproveRequired) || _vm.editable,"name":"costTypeCodeName","label":"费用类型","placeholder":"请选择","disabled":!((_vm.isEditField('costTypeCode') && _vm.isApproveRequired) || _vm.editable),"clearable":"","input-align":"right","error-message-align":"right","required":_vm.isEditField('costTypeCode') && _vm.isApproveRequired,"rules":[
          {
            message: '请输入费用类型',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.workorderCostTypesSelect()}},model:{value:(_vm.form.costTypeCodeName),callback:function ($$v) {_vm.$set(_vm.form, "costTypeCodeName", $$v)},expression:"form.costTypeCodeName"}}),(_vm.form.costTypeCode === '5')?_c('van-field',{attrs:{"clickable":"","name":"cost","label":"定义费用类型","placeholder":"请输入费用类型","disabled":!((_vm.isEditField('costTypeCode') && _vm.isApproveRequired) || _vm.editable),"clearable":"","input-align":"right","required":_vm.isEditField('costTypeCode') && _vm.isApproveRequired,"error-message-align":"right","rules":[
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z0-9\-]{0,20}$/,
            message: '请添加费用类型描述，20字以内',
            trigger: 'onChange'
          }
        ]},model:{value:(_vm.form.costTypeOtherName),callback:function ($$v) {_vm.$set(_vm.form, "costTypeOtherName", $$v)},expression:"form.costTypeOtherName"}}):_vm._e()],2)],1):_vm._e(),_c('bottom-fixed-box',[(_vm.type === 'add' || _vm.form.status === 1)?[_c('van-button',{staticClass:"btn-default",on:{"click":_vm.save}},[_vm._v("保存")]),_c('van-button',{staticClass:"btn-primary",on:{"click":_vm.submit}},[_vm._v("提交")])]:_vm._e(),(_vm.isShowAuditBtn)?[_c('van-button',{staticClass:"btn-primary",on:{"click":_vm.approve}},[_vm._v("提交")])]:_vm._e()],2),_c('select-popup',{attrs:{"choose-value":_vm.form.ticketsTypeId,"data":_vm.workorderTypeSltData,"title":"工单类型","visible":_vm.workorderTypeVisiable,"popup-style":{
      height: '70vh'
    },"value-key":"value","label-key":"label"},on:{"close":function($event){_vm.workorderTypeVisiable = false},"confirm":_vm.workorderTypeChange}}),_c('select-popup',{attrs:{"choose-value":_vm.form.costTypeCode,"data":_vm.costTypes,"title":"工单类型","visible":_vm.workorderCostTypeVisiable,"popup-style":{
      height: '40vh'
    },"value-key":"code","label-key":"name"},on:{"close":function($event){_vm.workorderCostTypeVisiable = false},"confirm":_vm.workorderCostTypeChange}}),_c('SelectPopupGrade',{attrs:{"multi":true,"filterable":false,"immediately":false,"list-key":"value","title":"承包商单位","list":_vm.contractorNames,"visible":_vm.contractorsVisiable},on:{"change":_vm.contractorsChange,"close":function($event){_vm.contractorsVisiable = false}},model:{value:(_vm.form.contractors),callback:function ($$v) {_vm.$set(_vm.form, "contractors", $$v)},expression:"form.contractors"}}),_c('select-popup',{attrs:{"choose-value":_vm.form.ticketsTypeId,"data":_vm.costCenterSltData,"title":"成本中心","visible":_vm.costCenterVisiable,"popup-style":{
      height: '70vh'
    },"value-key":"id","label-key":"costCenterName"},on:{"close":function($event){_vm.costCenterVisiable = false},"confirm":_vm.costCenterChange}}),_c('select-popup',{attrs:{"choose-value":_vm.form.deviceFaultId,"data":_vm.linkFaultSltData,"title":"关联故障","visible":_vm.linkFaultVisiable,"popup-style":{
      height: '70vh'
    },"value-key":"id","label-key":"description"},on:{"close":function($event){_vm.linkFaultVisiable = false},"confirm":_vm.linkFaultConfirm}}),_c('department-tree',{attrs:{"visible":_vm.MDVisiable,"title":"维修部门","multiple":true,"disabled-key":"noauth"},on:{"close":function($event){_vm.MDVisiable = false},"confirm":_vm.MDConfirm},model:{value:(_vm.form._maintenanceDepartment),callback:function ($$v) {_vm.$set(_vm.form, "_maintenanceDepartment", $$v)},expression:"form._maintenanceDepartment"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }