<template>
  <div class="has-nav-bar view-workticket-process">
    <van-nav-bar
      title="审批流程"
      :border="false"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="steps-box">
      <div>
        <van-steps
          direction="vertical"
          :active="active"
          active-icon="success"
          active-color="#1676FF"
        >
          <van-step v-for="(em, i) in list.records" :key="i">
            <template #inactive-icon>
              <van-icon
                v-if="em.passStatus === 1"
                name="checked"
                color="#1676FF"
                size="26"
              />
              <div v-if="em.passStatus === 0">
                <div class="check-style-equal-width"></div>
              </div>
              <div v-if="em.passStatus === -1" class="inactive-icon">
                <div class="check-style-unequal-width"></div>
              </div>
            </template>
            <div class="process-item">
              <div v-if="em.signTypeName" class="title-line">
                {{ em.nodeName }}({{ em.signTypeName }})
              </div>
              <div v-else class="title-line">
                {{ em.nodeName }}
              </div>
              <div v-if="em.approvePersons.length">
                <div v-for="(el, key) in em.approvePersons" :key="key">
                  <div class="time">
                    <span v-if="el.approvePersonName" class="icon-item"
                      >{{ el.approvePersonName
                      }}<van-icon
                        v-if="el.approveStatus === 1"
                        class="success"
                        name="checked"
                        color="#F68E0F"
                      />{{ el.approveTime }}</span
                    >
                    <span
                      v-if="el.approveContent"
                      :class="[el.show ? 'change-icon-down' : 'change-icon-up']"
                      @click="onIsUnfoldRecordsRepair(el)"
                    ></span>
                  </div>

                  <span v-if="el.show" class="content">{{
                    el.approveContent
                  }}</span>
                </div>
              </div>
              <div v-if="em.positionApprovePersons">
                <div
                  v-for="(value, key, index) in em.positionApprovePersons"
                  :key="index"
                  style="diaplay:inline-block"
                >
                  {{ key }}(<span
                    v-for="(va, k, ind) in em.positionApprovePersons[key]"
                    :key="ind"
                    style="diaplay:inline-block"
                  >
                    {{ va.approvePersonName }}

                    <van-icon
                      v-if="va.approveStatus === 1"
                      class="success"
                      name="checked"
                      color="#F68E0F"/></span
                  >)
                </div>
              </div>
            </div>
          </van-step>
        </van-steps>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaintenanceProcessById } from "@/api/psm/workorder";

export default {
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      active: 99999,
      list: []
    };
  },
  created: function() {},
  mounted: function() {
    const params = { recordId: this.id };
    getMaintenanceProcessById(this.id).then(res => {
      this.list = res;
      this.list.records.unshift({
        nodeName: "提交",
        passStatus: 1,
        sortId: -1,
        approvePersons: [
          {
            approvePersonName: res.ticketMaintenanceSubmitPersonName,
            approveTime: res.ticketMaintenanceSubmitTime,
            approveContent: null
          }
        ]
      });
      this.list.records.forEach((item, index) => {
        if (item.passStatus === 1) {
          this.$set(item, "icon", "el-icon-success");
        } else {
          this.$set(item, "icon", "iconfont iconico-cir-his");
        }
        item.approvePersons.forEach((el, key) => {
          this.$set(el, "show", false);
        });
      });
      this.list.records.sort((a, b) => {
        return a.sortId - b.sortId;
      });
    });
  },
  methods: {
    onIsUnfoldRecordsRepair(el) {
      this.list.records.forEach((item, index) => {
        item.approvePersons.forEach((ele, key) => {
          if (ele.id == el.id) {
            ele.show = !el.show;
          }
        });
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.inactive-icon {
  display: flex;
  width: 12px;
  height: 12px;
  background: #c0c4cc;
  border: 2px solid #c0c4cc;
  border-radius: 50%;
}
.van-step--finish .inactive-icon {
  background-color: #c0c4cc;
}
.check-style-equal-width {
  width: 16px;
  height: 16px;
  display: flex;
  background: #ffffff;
  border: 2px solid #1676ff;
  border-radius: 50%;
}
.active-icon {
  display: flex;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 2px solid $--color-primary;
  border-radius: 50%;
  background: $--color-primary;

  justify-content: center;
  .check-style-unequal-width {
    width: 5px;
    height: 10px;
    border-color: #ffffff;
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);

    // padding-bottom: 2px;
  }
}
</style>

<style lang="scss" scoped>
.view-workticket-process {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.steps-box {
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
}

.process-item {
  font-size: 12px;
  color: $--color-gray-item-text;

  .title-line {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    color: $--color-black-title;

    .title {
      flex-grow: 1;
    }
  }
  .time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
  .icon-item {
    display: flex;
    margin-right: 20px;
    color: #8c8f97;
    align-items: center;
  }
  .success {
    padding-right: 6px;
  }
  .change-icon-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #c0c4cc;
  }
  .change-icon-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #c0c4cc;
  }
  .content {
    margin-bottom: 12px;
    padding: 10px 12px;
    font-size: 12px;
    color: #8c8f97;
    line-height: 20px;
    background: #f3f5fa;
    border-radius: 4px;
    display: block;
  }
}
</style>
